import "./App.css";
import Home from "./Home";
import Link from "./Link";

function App() {
  const urlData = window.location.pathname.replaceAll("/", "");
  const pageToLoad = urlData ? <Link destination={urlData} /> : <Home />;

  return <main>{pageToLoad}</main>;
}

export default App;
