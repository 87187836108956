interface LinkProps {
  destination: string;
}

export default function Link(props: LinkProps) {
  // todo: malformed url checking
  // todo: parse url parts

  window.location.replace("teleportlink://" + props.destination);
  setTimeout(() => {
    window.close();
  }, 3000);

  return (
    <div>
      <p>taking you to</p>
      <h2>{props.destination}</h2>
      <p>(subtext: pitch, bank, heading)</p>
      <p>
        Show a message about how we opened the link, but you might not have
        teleportlink so should go home.
      </p>
      <p>the final version of this will function better</p>
    </div>
  );
}
